<template>
    <section class="logo-carousel">
        <h3>{{ $t("Our assessments are inspired by the following companies") }}</h3>
        <div class="logos">
            <div class="logos-slide">
                <img v-for="(logo, index) in logos" :key="index" :src="logo.src" :alt="logo.alt" />
            </div>
            <!-- Repeat logos for smooth looping effect -->
            <div class="logos-slide">
                <img v-for="(logo, index) in logos" :key="'repeat-' + index" :src="logo.src" :alt="logo.alt" />
            </div>
        </div>
    </section>
</template>

<script>
import PWC_logo from "@/assets/companies-logos/PWC-logo.svg";
import KPMG_logo from "@/assets/companies-logos/KPMG-logo.svg";
import DELOITTE_logo from "@/assets/companies-logos/Deloitte-logo.svg";
import AWS_logo from "@/assets/companies-logos/AWS-logo.svg";
import EY_logo from "@/assets/companies-logos/EY-logo.svg";

export default {
    name: "LogoCarousel",
    data() {
        return {
            logos: [
                { src: PWC_logo, alt: "PricewaterhouseCoopers Logo" },
                { src: KPMG_logo, alt: "KPMG Logo" },
                { src: DELOITTE_logo, alt: "Deloitte Logo" },
                { src: AWS_logo, alt: "Amazon Web Services Logo" },
                { src: EY_logo, alt: "Ernst & Young Logo" },
            ],
        };
    },
};
</script>

<style scoped>
.logo-carousel {
    padding: 60px 40px;
    background-color: #ffffff;
    width: 100%;
}

.logo-carousel h3 {
    text-align: center;
    color: #334155;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 36px;
}

.logos {
    width: 100%;
    overflow: hidden;
    background-color: #ffffff;
    padding: 20px 0;
    white-space: nowrap;
}

.logos-slide {
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
    animation: 10s scroll infinite linear;
}

.logos img {
    width: 100px;
    height: auto;
    margin: 0 70px;
}

@keyframes scroll {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

@media (max-width: 1023px) {
    .logo-carousel h3 {
        font-size: 12px;
    }

    .logos img {
        width: 60px;
        height: 30px;
        margin: 0 30px;
    }
}
</style>
