<template>
    <div class="tool-section">
        <template v-if="contentFirst">
            <div class="content">
                <IconButton :iconSrc="buttonIconSrc" :iconWidth="buttonIconWidth" :iconHeight="buttonIconHeight" :buttonText="buttonText" />
                <h1>
                    <slot></slot>
                </h1>
                <p>{{ description }}</p>
                <ul class="icon-list">
                    <li v-for="(item, index) in iconList" :key="index"><i class="fa-regular fa-circle-check"></i>{{ item }}</li>
                </ul>
                <router-link to="/register" class="cta-button"> Try for free </router-link>
            </div>
            <div class="image-container content-first">
                <img :src="imageSrc" :alt="imageAlt" :class="imageClass" :style="{ width: imageWidth, height: imageHeight }" />
            </div>
        </template>
        <template v-else>
            <div class="image-container image-first">
                <img :src="imageSrc" :alt="imageAlt" :class="imageClass" :style="{ width: imageWidth, height: imageHeight }" />
            </div>
            <div class="content">
                <IconButton :iconSrc="buttonIconSrc" :iconWidth="buttonIconWidth" :iconHeight="buttonIconHeight" :buttonText="buttonText" />
                <h1>
                    <slot></slot>
                </h1>
                <p>{{ description }}</p>
                <ul class="icon-list">
                    <li v-for="(item, index) in iconList" :key="index"><i class="fa-regular fa-circle-check"></i>{{ item }}</li>
                </ul>
                <router-link to="/register" class="cta-button"> Try for free </router-link>
            </div>
        </template>
    </div>
</template>

<script>
import IconButton from "./IconButton.vue";

export default {
    name: "ToolSection",
    props: {
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            default: "",
        },
        iconList: {
            type: Array,
            required: true,
        },
        imageSrc: {
            type: String,
            required: true,
        },
        imageAlt: {
            type: String,
            default: "",
        },
        imageClass: {
            type: String,
            default: "",
        },
        contentFirst: {
            type: Boolean,
            default: true, // Default to content first
        },
        imageWidth: {
            type: String,
            default: "100%",
        },
        imageHeight: {
            type: String,
            default: "auto",
        },
        buttonIconSrc: {
            type: String,
            required: true,
        },
        buttonIconWidth: {
            type: String,
            default: "20px",
        },
        buttonIconHeight: {
            type: String,
            default: "20px",
        },
        buttonText: {
            type: String,
            required: true,
        },
    },
    components: {
        IconButton,
    },
};
</script>

<style scoped>
.tool-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
}

.content {
    flex: 1; /* Ensures both content and image take equal space */
    max-width: 50%;
    box-sizing: border-box;
    padding: 0 20px;
    text-align: left;
}

.image-container {
    flex: 1; /* Ensures both content and image take equal space */
    max-width: 50%;
    box-sizing: border-box;
}

.image-container.content-first {
    display: flex;
    justify-content: right; /* Centers the image */
    align-items: center;
}

.image-container.image-first {
    display: block;
}

.content h1 {
    font-size: 24px;
    font-weight: 700;
    color: #172b4d;
}

.content p {
    font-size: 16px;
    color: #172b4d;
    margin: 25px 0;
}

.icon-list {
    margin-bottom: 25px;
}

.icon-list li {
    margin-bottom: 10px;
    color: #172b4d;
}

.icon-list i {
    margin-right: 10px;
}

.cta-button {
    display: inline-block;
    padding: 12px 28px;
    color: #ffffff;
    background-color: #2196f3;
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    text-decoration: none;
    overflow: hidden;
}

.tool-section span img {
    width: 400px;
    height: auto;
}

@media (max-width: 1023px) {
    .tool-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        margin-top: 32px;
    }

    /* Ensure content takes full width */
    .content {
        width: 100%;
        max-width: 100%;
        order: 1; /* Content comes first */
        padding: 0;
        text-align: center;
    }

    /* Ensure image takes full width and appears below content */
    .image-container {
        width: 100%;
        max-width: 100%;
        order: 2; /* Image comes after content */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .content h1 {
        font-size: 16px;
        margin-bottom: 16px;
        line-height: 1.4;
        text-align: left;
    }

    .content p {
        display: none; /* Hide paragraphs on mobile */
    }

    .icon-list {
        margin-bottom: 16px;
    }

    .icon-list li {
        font-size: 12px;
        line-height: 1.5;
        margin-bottom: 12px;
        text-align: left;
    }

    .icon-list i {
        font-size: 12px;
        margin-right: 8px;
        margin-top: 2px;
    }

    .cta-button {
        font-size: 14px;
        padding: 10px 24px;
        margin: 24px auto 0; /* Center the button */
        display: block;
        width: 100%;
        background-color: #2196f3;
        color: white !important;
        border-radius: 5px;
    }

    .image-container img {
        max-width: 220px;
        width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
    }
}
</style>
