<template>
    <div class="reviews-cards-container">
        <ReviewsCard :image-path="googleLogo" :reviews-number="142" />
        <ReviewsCard :image-path="producthuntLogo" :reviews-number="29" />
    </div>
</template>

<script>
import ReviewsCard from "./ReviewsCard.vue";
import googleLogo from "@/assets/GoogleLogo.svg";
import producthuntLogo from "@/assets/ProductHuntLogo.svg";
export default {
    name: "ReviewsCards",
    components: {
        ReviewsCard,
    },
    data() {
        return {
            googleLogo: googleLogo,
            producthuntLogo: producthuntLogo,
        };
    },
};
</script>

<style scoped>
.reviews-cards-container {
    display: flex;
    gap: 40px;
    margin: 40px;
}
/* Responsive Styles */
@media (max-width: 1023px) {
    .reviews-cards-container {
        display: flex;
        flex-direction: column;
        margin: 40px;
    }
}
</style>
