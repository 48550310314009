<template>
    <div class="skills-assessment-card">
        <div class="info">
            <h5>{{ title }}</h5>
            <p><slot></slot></p>
        </div>
    </div>
</template>

<script>
export default {
    name: "SkillsAssessmentCard",
    props: {
        title: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped>
.skills-assessment-card {
    /*background-color: #FFFFFF;*/
    width: 16.6em;
    /*box-shadow: 6px 6px 4px rgba(0, 0, 0, 25%);
    border-radius: 10px;
    padding: 30px 20px;*/
}

.skills-assessment-card .info h5 {
    font-size: 20px;
    font-weight: 700;
    text-align: start;
    color: #334155;
    margin-bottom: 10px;
}

.skills-assessment-card .info p {
    font-size: 16px;
    color: #334155;
    text-align: start;
}

@media (max-width: 1023px) {
    .skills-assessment-card {
        width: 100%;
        max-width: 480px;
        margin-bottom: 20px;
    }

    .skills-assessment-card .info h5 {
        font-size: 14px;
        text-align: center;
    }

    .skills-assessment-card .info p {
        font-size: 12px;
        text-align: center;
    }
}
</style>
