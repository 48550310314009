<template>
    <!-- Dropdown menu -->

    <div class="absolute bg-white left-0 right-0 mx-96 pl-24 lg:flex justify-between top-full border-2">
        <div class="p-0 pr-4 lg:flex lg:justify-between lg:w-3/6 sm:justify-center right-6">
            <ul class="text-sm text-gray-700 text-left" aria-labelledby="dropdownLargeButton">
                <h6 class="font-medium text-sm text-gray-500 px-5 mt-8 mb-4">{{ $t("Learn") }}</h6>
                <li class="">
                    <router-link to="/" class="px-4 py-4 transition-all duration-500 hover:bg-gray-50 hover:rounded-xl flex">
                        <div class="rounded-lg w-12 h-12 flex justify-center">
                            <div class="bg-emerald-50 rounded-lg w-12 h-12 flex items-center justify-center">
                                <font-awesome-icon class="icon" :icon="['fa-solid', 'fa-laptop']" style="color: #2196f3" />
                            </div>
                        </div>
                        <div class="ml-4 w-4/5">
                            <h5 class="text-gray-900 text-base mb-1.5 font-semibold">{{ $t("Case Studies") }}</h5>
                            <p class="text-xs font-medium text-gray-400">{{ $t("The Research Teams Progress") }}</p>
                        </div>
                    </router-link>
                </li>
                <li class="">
                    <router-link to="/blogs" class="px-4 py-4 hover:bg-gray-50 hover:rounded-xl flex transition-all duration-500">
                        <div class="rounded-lg w-12 h-12 flex justify-center">
                            <div class="bg-emerald-50 rounded-lg w-12 h-12 flex items-center justify-center">
                                <font-awesome-icon class="icon" :icon="['fa-solid ', 'fa-book-open']" style="color: #2196f3" />
                            </div>
                        </div>
                        <div class="ml-4 w-4/5">
                            <h5 class="text-gray-900 text-base mb-1.5 font-semibold">
                                {{ $t("Blogs") }}
                                <span class="bg-indigo-50 text-indigo-500 text-xs font-medium mr-2 px-2.5 py-1 rounded-full h-5 ml-4">{{ $t("New") }}</span>
                            </h5>
                            <p class="text-xs font-medium text-gray-400">{{ $t("Be Part Of Our Vision") }}</p>
                        </div>
                    </router-link>
                </li>
                <!-- <li class="">
                    <router-link to="javascript:;" class="px-4 py-4 hover:bg-gray-50 hover:rounded-xl flex transition-all duration-500">
                        <div class="rounded-lg w-12 h-12 flex justify-center">
                            <div class="bg-emerald-50 rounded-lg w-12 h-12 flex items-center justify-center">
                                <font-awesome-icon class="icon" :icon="['fa-solid ', 'fa-chalkboard-user']" style="color: #2196f3" />
                            </div>
                        </div>
                        <div class="ml-4 w-4/5">
                            <h5 class="text-gray-900 text-base mb-1.5 font-semibold">{{ $t("Skill-Based Hiring Hub") }}</h5>
                            <p class="text-xs font-medium text-gray-400">{{ $t("Hire A Top Notch") }}</p>
                        </div>
                    </router-link>
                </li> -->

                <li class="">
                    <router-link to="/Talents" class="px-4 py-4 hover:bg-gray-50 hover:rounded-xl flex transition-all duration-500">
                        <div class="rounded-lg w-12 h-12 flex justify-center">
                            <div class="bg-emerald-50 rounded-lg w-12 h-12 flex items-center justify-center">
                                <font-awesome-icon class="icon" :icon="['fa-solid ', 'fa-ranking-star']" style="color: #2196f3" />
                            </div>
                        </div>
                        <div class="ml-4 w-4/5">
                            <h5 class="text-gray-900 text-base mb-1.5 font-semibold">{{ $t("Talent Assessement Hub") }}</h5>
                            <p class="text-xs font-medium text-gray-400">{{ $t("Our Protocol Of Talent Assessement") }}</p>
                        </div>
                    </router-link>
                </li>
                <!-- <router-link to="javascript:;" class="flex items-center mt-4 mb-12 text-xs font-semibold text-indigo-600"
                    >See All Ressources
                    <svg class="ml-2" width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M2 8L12.6667 8M9.33333 12L12.8619 8.4714C13.0842 8.24918 13.1953 8.13807 13.1953 8C13.1953 7.86193 13.0842 7.75082 12.8619 7.5286L9.33333 4"
                            stroke="#4F46E5"
                            stroke-width="1.6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        ></path>
                    </svg>
                </router-link> -->
            </ul>
        </div>
        <div class="bg-gray-50 p-8 lg:w-3/6">
            <h6 class="font-medium text-sm text-gray-500 mb-16">{{ $t("Hire Skilled Personal") }}</h6>
            <div class="mb-6 relative">
                <img src="@/assets/Images/palt.svg" alt="Latest News image" class="w-full h-full" />
            </div>
            <div class="block">
                <h5 class="text-gray-900 text-base mb-1.5 font-semibold">{{ $t("Remote Job Board") }}</h5>
                <p class="text-sm font-medium text-gray-400">{{ $t("Our Skill based job board help you discover remote roles at companies who value your true potentiel") }}</p>
                <!-- <router-link to="javascript:;" class="flex items-center mt-10 text-xs font-semibold text-indigo-600"
                    >See All Ressources
                    <svg class="ml-2" width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M2 8L12.6667 8M9.33333 12L12.8619 8.4714C13.0842 8.24918 13.1953 8.13807 13.1953 8C13.1953 7.86193 13.0842 7.75082 12.8619 7.5286L9.33333 4"
                            stroke="#4F46E5"
                            stroke-width="1.6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        ></path>
                    </svg>
                </router-link> -->
            </div>
        </div>
    </div>

    <div class="flex flex-col lg:flex-row gap-y-4 lg:items-center ml-auto justify-center"></div>
</template>
