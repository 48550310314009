<template>
    <section class="CallToAction">
        <div class="logo-container">
            <a href="#">
                <img :src="logo" alt="GO Platform" />
            </a>
        </div>
        <h1>Unlock the full potential of your workforce</h1>
        <h3>Learn how GO PLATFORM can power digital transformation and produce measurable results across your enterprise.</h3>
        <router-link to="/register" class="main-button"> Start For Free </router-link>
    </section>
</template>

<script>
import logo from "@/assets/Images/go_logo_white.svg";
export default {
    name: "CallToAction",
    data() {
        return {
            logo: logo,
        };
    },
};
</script>

<style scoped>
.CallToAction {
    background: linear-gradient(to bottom, #3b6ff7 34%, #5652fb 62%, #7036fe 100%);
    padding: 70px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.CallToAction .logo-container {
    /* background-color: #f5f8ff; */
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 40px;
}

.CallToAction a img {
    width: 300px;
}

.CallToAction h1 {
    color: #ffffff;
    font-size: 36px;
    font-weight: 600;
}

.CallToAction h3 {
    text-align: center;
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
    margin: 20px 0;
}

.CallToAction .main-button {
    margin-top: 40px;
    background-color: #f5f8ff;
    color: #334155;
}

@media (max-width: 1023px) {
    .CallToAction {
        padding: 30px 10px;
    }

    .CallToAction .logo-container {
        margin-bottom: 30px;
        padding: 5px;
    }

    .CallToAction a img {
        width: 100px;
    }

    .CallToAction h1 {
        font-size: 18px;
        text-align: center;
    }

    .CallToAction h3 {
        font-size: 12px;
        margin: 10px 0;
    }

    .CallToAction .main-button {
        padding: 10px 20px;
        font-size: 12px;
        width: 90%;
    }
}
</style>
