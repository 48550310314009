<template>
    <nav></nav>
    <div class="absolute bg-white grid grid-cols-1 lg:grid-cols-3 justify-between mx-40 px-16 top-full border-x-2 border-b-2 left-6 sm:right-4">
        <ul class="text-sm text-gray-700 text-left lg:justify-items-start">
            <h6 class="font-medium text-sm text-gray-500 px-5 mt-8 mb-4">{{ $t("Product Information") }}</h6>
            <li class="w-full">
                <router-link to="/Tour" class="px-4 py-4 transition-all duration-500 hover:bg-gray-50 hover:rounded-xl flex items-center">
                    <div class="bg-emerald-50 rounded-lg w-12 h-12 flex items-center justify-center">
                        <font-awesome-icon class="icon" :icon="['fa-solid', 'fa-route']" style="color: #2196f3" />
                    </div>
                    <div class="ml-4 w-4/5">
                        <h5 class="text-gray-900 text-base mb-1.5 font-semibold">{{ $t("Product Tour") }}</h5>
                        <p class="text-xs font-medium text-gray-400">{{ $t("See Our Features In Actions") }}</p>
                    </div>
                </router-link>
            </li>
            <li class="w-full">
                <router-link to="/SciencePage" class="px-4 py-4 hover:bg-gray-50 hover:rounded-xl flex items-center transition-all duration-500">
                    <div class="bg-emerald-50 rounded-lg w-12 h-12 flex items-center justify-center">
                        <font-awesome-icon class="icon" :icon="['fa-solid', 'fa-flask-vial']" style="color: #2196f3" />
                    </div>
                    <div class="ml-4 w-4/5">
                        <h5 class="text-gray-900 text-base mb-1.5 font-semibold">
                            {{ $t("Science") }}
                            <span class="bg-indigo-50 text-indigo-500 text-xs font-medium mr-2 px-2.5 py-1 rounded-full h-5 ml-4">{{ $t("New") }}</span>
                        </h5>
                        <p class="text-xs font-medium text-gray-400">{{ $t("Skill-Based Hiring Method") }}</p>
                    </div>
                </router-link>
            </li>
            <!-- <li class="w-full">
                <router-link to="javascript:;" class="flex items-center mt-4 text-xs font-semibold text-indigo-600"
                    >See All Infos
                    <svg class="ml-2" width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M2 8L12.6667 8M9.33333 12L12.8619 8.4714C13.0842 8.24918 13.1953 8.13807 13.1953 8C13.1953 7.86193 13.0842 7.75082 12.8619 7.5286L9.33333 4"
                            stroke="#4F46E5"
                            stroke-width="1.6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        ></path>
                    </svg>
                </router-link>
            </li> -->

            <!-- <li aria-labelledby="dropdownNavbarLink"><button id="doubleDropdownButton" data-dropdown-toggle="doubleDropdown" data-dropdown-placement="right-start" type="button" class="flex items-center justify-between w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Dropdown<svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg></button><div id="doubleDropdown" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"><ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="doubleDropdownButton"><li><router-link to="javascript:;" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white">Overview</router-link></li><li><router-link to="javascript:;" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white">My
                                downloads</router-link></li><li><router-link to="javascript:;" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white">Billing</router-link></li><li><router-link to="javascript:;" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white">Rewards</router-link></li></ul></div></li> -->
        </ul>
        <ul class="text-sm text-gray-700 text-left lg:justify-items-start">
            <h6 class="px-5 font-medium text-sm text-gray-500 mt-8 mb-4">{{ $t("Test library") }}</h6>
            <li class="w-full">
                <router-link to="/assessments-library/Software Development" class="px-4 py-4 hover:bg-gray-50 hover:rounded-xl flex items-center transition-all duration-500">
                    <div class="bg-emerald-50 rounded-lg w-12 h-12 flex items-center justify-center">
                        <font-awesome-icon class="icon" :icon="['fa-solid', 'fa-code']" style="color: #2196f3" />
                    </div>
                    <div class="ml-4 w-4/5">
                        <h5 class="text-gray-900 text-base mb-1.5 font-semibold">{{ $t("Programming") }}</h5>
                        <p class="text-xs font-medium text-gray-400">{{ $t("Assess Coding Skill") }}</p>
                    </div>
                </router-link>
            </li>
            <li class="w-full">
                <router-link to="/assessments-library/Sales and Marketing" class="px-4 py-4 hover:bg-gray-50 hover:rounded-xl flex items-center transition-all duration-500">
                    <div class="bg-emerald-50 rounded-lg w-12 h-12 flex items-center justify-center">
                        <font-awesome-icon class="icon" :icon="['fa-brands', 'fa-salesforce']" style="color: #2196f3" />
                    </div>
                    <div class="ml-4 w-4/5">
                        <h5 class="text-gray-900 text-base mb-1.5 font-semibold">{{ $t("Sales And Marketing") }}</h5>
                        <p class="text-xs font-medium text-gray-400">{{ $t("Evaluate Marketing Skill") }}</p>
                    </div>
                </router-link>
            </li>
            <li class="w-full">
                <router-link to="/assessments-library/Finance and Accounting" class="px-4 py-4 hover:bg-gray-50 hover:rounded-xl flex items-center transition-all duration-500">
                    <div class="bg-emerald-50 rounded-lg w-12 h-12 flex items-center justify-center">
                        <font-awesome-icon class="icon" :icon="['fa-solid', 'fa-coins']" style="color: #2196f3" />
                    </div>
                    <div class="ml-4 w-4/5">
                        <h5 class="text-gray-900 text-base mb-1.5 font-semibold">{{ $t("Finance And Accounting") }}</h5>
                        <p class="text-xs font-medium text-gray-400">{{ $t("Test Economies Skills") }}</p>
                    </div>
                </router-link>
            </li>
            <li class="w-full">
                <router-link to="/assessments-library/All" class="flex items-center mt-4 mb-14 text-xs font-semibold text-indigo-600"
                    >{{ $t("See All Tests") }}
                    <svg class="ml-2" width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M2 8L12.6667 8M9.33333 12L12.8619 8.4714C13.0842 8.24918 13.1953 8.13807 13.1953 8C13.1953 7.86193 13.0842 7.75082 12.8619 7.5286L9.33333 4"
                            stroke="#4F46E5"
                            stroke-width="1.6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        ></path>
                    </svg>
                </router-link>
            </li>
            <!-- <li aria-labelledby="dropdownNavbarLink"><button id="doubleDropdownButton" data-dropdown-toggle="doubleDropdown" data-dropdown-placement="right-start" type="button" class="flex items-center justify-between w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Dropdown<svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg></button><div id="doubleDropdown" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"><ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="doubleDropdownButton"><li><router-link to="javascript:;" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white">Overview</router-link></li><li><router-link to="javascript:;" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white">My
                                 downloads</router-link></li><li><router-link to="javascript:;" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white">Billing</router-link></li><li><router-link to="javascript:;" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white">Rewards</router-link></li></ul></div></li> -->
        </ul>
        <ul class="text-sm text-gray-700 text-left lg:justify-items-start">
            <h6 class="px-5 font-medium text-sm text-gray-500 mt-8 mb-4">{{ $t("Solutions") }}</h6>
            <li class="w-full">
                <router-link to="/BPO-and-outsourcing" class="px-4 py-4 hover:bg-gray-50 hover:rounded-xl flex items-center transition-all duration-500">
                    <div class="bg-emerald-50 rounded-lg w-12 h-12 flex items-center justify-center">
                        <font-awesome-icon class="icon" :icon="['fa-solid ', 'fa-globe']" style="color: #2196f3" />
                    </div>
                    <div class="ml-4 w-4/5">
                        <h5 class="text-gray-900 text-base mb-1.5 font-semibold">{{ $t("BPO And Outsourcing") }}</h5>
                        <p class="text-xs font-medium text-gray-400">{{ $t("Improve Hiring Quality") }}</p>
                    </div>
                </router-link>
            </li>
            <li class="w-full">
                <router-link to="/assessments-library/IT-services" class="px-4 py-4 hover:bg-gray-50 hover:rounded-xl flex items-center transition-all duration-500">
                    <div class="bg-emerald-50 rounded-lg w-12 h-12 flex items-center justify-center">
                        <font-awesome-icon class="icon" :icon="['fa-solid ', 'fa-headset']" style="color: #2196f3" />
                    </div>
                    <div class="ml-4 w-4/5">
                        <h5 class="text-gray-900 text-base mb-1.5 font-semibold">{{ $t("IT Services") }}</h5>
                        <p class="text-xs font-medium text-gray-400">{{ $t("High Quality IT") }}</p>
                    </div>
                </router-link>
            </li>
            <!-- <li class="w-full">
                <router-link to="javascript:;" class="flex items-center mt-4 text-xs font-semibold text-indigo-600"
                    >See All Solutions
                    <svg class="ml-2" width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M2 8L12.6667 8M9.33333 12L12.8619 8.4714C13.0842 8.24918 13.1953 8.13807 13.1953 8C13.1953 7.86193 13.0842 7.75082 12.8619 7.5286L9.33333 4"
                            stroke="#4F46E5"
                            stroke-width="1.6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        ></path>
                    </svg>
                </router-link>
            </li> -->
        </ul>
    </div>
</template>
