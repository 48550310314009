<template>
    <div class="about-us-card">
        <div class="icon">
            <!--<i :class="`fa-solid ${icon}`"></i>-->
            <font-awesome-icon :icon="['fas', icon]" style="color: #5d5fef" />
        </div>
        <div class="info">
            <h5 class="card-title">{{ title }}</h5>
            <p>
                <slot></slot>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "AboutUsCard",
    props: {
        icon: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped>
.about-us-card {
    background-color: #ffffff;
    width: 30%;
    box-shadow: 6px 6px 4px rgba(0, 0, 0, 25%);
    border-radius: 10px;
    padding: 30px 20px;
    transition: 0.7s ease;
}

.icon {
    color: #5d5fef;
    font-size: 2.5em;
    text-align: center;
}

.info {
    text-align: center;
}

.info h5 {
    color: #5d5fef;
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;
}

.info p {
    color: #4a4a4b;
    font-size: 14px;
    font-weight: 400;
    margin-top: 15px;
}

@media (max-width: 1023px) {
    .about-us-card {
        width: 80%;
        max-width: 480px;
        margin-bottom: 20px;
    }

    .icon {
        font-size: 2em;
    }

    .info h5 {
        font-size: 14px;
    }

    .info p {
        font-size: 12px;
    }
}
</style>
