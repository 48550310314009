<template>
    <div class="reviews-card">
        <div class="card-content">
            <img :src="imagePath" alt="Logo" class="logo" />
            <div class="info">
                <h2>{{ $t("Our Rating") }}</h2>
                <div class="rating">
                    <h3 class="rating-value">5.0</h3>
                    <div class="stars">
                        <font-awesome-icon :icon="['fas', 'star']" class="starIcon" style="color: #fea500" />
                        <font-awesome-icon :icon="['fas', 'star']" class="starIcon" style="color: #fea500" />
                        <font-awesome-icon :icon="['fas', 'star']" class="starIcon" style="color: #fea500" />
                        <font-awesome-icon :icon="['fas', 'star']" class="starIcon" style="color: #fea500" />
                        <font-awesome-icon :icon="['fas', 'star']" class="starIcon" style="color: #fea500" />
                    </div>
                </div>
                <h3>Based on {{ reviewsNumber }} reviews</h3>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ReviewsCard",
    props: {
        imagePath: {
            type: String,
            required: true,
        },
        reviewsNumber: {
            type: Number,
            required: true,
        },
    },
};
</script>

<style scoped>
.reviews-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 4px 4px 8px rgba(51, 97, 255, 0.098);
    display: flex;
    align-items: center;
}

.card-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 35px;
    margin: 0;
}

.card-content .logo {
    width: 50px;
    height: auto;
    /* Maintain aspect ratio */
    margin-right: 15px;
}

.info {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.info h2 {
    color: #334155;
    margin: 5px 0;
    font-size: 14px;
    font-weight: 600;
}

.rating {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.rating .rating-value {
    color: #fea500;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
}

.info h3 {
    color: #334155;
    margin: 5px 0;
    font-size: 12px;
    font-weight: 400;
}

/* Responsive Styles */
@media (max-width: 1023px) {
    .reviews-card {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
        box-shadow: none;
        border-radius: 4px;
    }

    .card-content {
        justify-content: flex-start;
        padding: 4px 4px;
        width: 100%;
    }

    .card-content .logo {
        width: 35px;
        height: auto;
    }

    .stars .starIcon {
        font-size: 13px;
    }

    .info h2,
    .info h3 {
        font-size: 12px;
        text-align: left;
        margin-bottom: 2px;
    }
}
</style>
