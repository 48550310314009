<template>
    <section class="flex flex-col justify-between items-center gap-5 py-10 px-20">
        <h1 class="text-2xl lg:text-5xl font-bold text-center lg:text-left text-slate-700 w-full">3. Analyze and decide on the best candidates.</h1>
        <h2 class="text-base lg:text-lg font-normal text-slate-700 text-center lg:text-left w-full">
            Watch the results roll in and discover your strongest candidates with GO PLATFORM’s easy-to-read output reports.
        </h2>
        <div class="flex flex-col justify-center items-center w-full lg:gap-[5rem] sm:gap-[2rem]">
            <div class="w-full mt-[5rem] flex flex-col lg:flex-row justify-between">
                <div class="flex flex-col justify-center items-center w-full lg:w-1/2">
                    <h1 class="text-xl lg:text-3xl font-semibold text-left w-full text-slate-700">Real-time assessment results</h1>
                    <p class="text-left text-base font-light text-slate-500 mt-6 mb-8">
                        Stay updated with real-time insights into your assessment process. Our platform provides instant access to assessment status and statistics, allowing you to monitor progress
                        effortlessly. Track how many candidates have started or completed the assessment, along with the timestamp of the latest activity. With this real-time visibility, you can stay
                        informed about the status of your assessments and take prompt action as needed. Say goodbye to guesswork and hello to data-driven decision-making with our real-time assessment
                        results feature
                    </p>
                    <a class="w-fit p-3 rounded-md text-white bg-[#2196f3] mr-auto px-5 font-semibold" href="/">Get Started</a>
                </div>
                <img loading="lazy" decoding="async" src="../../assets/Images/analyse_step1.png" alt="" class="w-full lg:w-[45%] aspect-auto" />
            </div>
            <div class="w-full flex flex-col lg:flex-row gap-4 lg:gap-12 relative">
                <img loading="lazy" decoding="async" src="../../assets/Images/talent_mapping.png" alt="" class="w-full lg:w-[45%] aspect-auto" />

                <div class="flex flex-col justify-center items-center w-full">
                    <h1 class="text-xl lg:text-3xl font-semibold text-left w-full text-slate-700">Compare your candidates instantly</h1>
                    <p class="text-left text-base font-light text-slate-500 mt-6 mb-8">
                        Effortlessly compare the performance of multiple candidates side by side. Our platform enables you to view key metrics and scores for each candidate simultaneously, making it
                        easy to identify top performers and areas for improvement. With instant comparison capabilities, you can quickly gauge which candidates align best with your hiring criteria and
                        make data-driven decisions with confidence.
                    </p>
                    <a class="w-fit p-3 rounded-md text-white bg-[#2196f3] mr-auto px-5 font-semibold" href="/">Get Started</a>
                </div>
            </div>
            <div class="w-full flex flex-col-reverse lg:flex-row-reverse gap-4 lg:gap-12">
                <img loading="lazy" decoding="async" src="../../assets/Images/candidate_evaluation.png" alt="" class="w-full lg:w-[45%] aspect-auto" />
                <div class="flex flex-col justify-center items-center">
                    <h1 class="text-xl lg:text-3xl font-semibold text-left w-full text-slate-700">Go in-depth with a candidate review</h1>
                    <p class="text-left text-base font-light text-slate-500 mt-6 mb-8">
                        Dive deep into each candidate's assessment results with our comprehensive candidate review feature. Gain valuable insights into their strengths, weaknesses, and overall
                        suitability for the role. Explore detailed breakdowns of their performance across different assessment sections or competencies. With our intuitive interface, conducting a
                        thorough candidate review has never been easier. Equip yourself with the information you need to select the best-fit candidates for your organization.
                    </p>
                    <a class="w-fit p-3 rounded-md text-white bg-[#2196f3] mr-auto px-5 font-semibold" href="/">Get Started</a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "AnalyzeSection",
    data() {
        return {
            step: 1,
        };
    },
};
</script>

<style lang="scss" scoped>
.buttons-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;

    @media (min-width: 640px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1024px) {
        grid-template-columns: repeat(4, 1fr);
    }
}

button {
    flex: 1;
    min-width: 0;
}

img {
    @media (max-width: 1200px) {
        display: none;
    }
}
</style>
